import React, {useEffect, useState} from 'react'
import {useWallet} from '@solana/wallet-adapter-react'
import {sign} from 'tweetnacl'
import {useSnackbar} from 'react-simple-snackbar';
import '../styles/Login.css'
import jeeves from '../svg/jeeves.svg'
import logo from '../svg/the-club-white.svg'
import mark from '../svg/check-mark.svg'
import avatar from '../svg/avatar.svg'
import Tabs from "./Tabs";

export default function Login() {
  const {wallet, publicKey, signMessage, connected} = useWallet();
  const [discordAccount, setDiscordAccount] = useState({username: null, avatar: null, discord_url: null, wallets: []});
  const [isDiscordConnected, setIsDiscordConnected] = useState(false);
  const [isAddressVerified, setAddressVerified] = useState(false);
  const [walletAlreadyIncluded, setWalletAlreadyIncluded] = useState(false);
  const [isAlternateAddressVerified, setAlternateAddressVerified] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [openSnackbar] = useSnackbar({position: 'top-right'});

  useEffect(() => {
    connectDiscord();
  }, []);

  useEffect(() => {
    if (publicKey) {
      setWalletAddress(publicKey.toBase58());
    }
  }, [publicKey]);

  useEffect(() => {
    if (window.solana) {
      window.solana.on('connect', () => {
        const address = window.solana.publicKey.toString();
        setWalletAddress(address);
        console.log('ON CONNECT', discordAccount, address);
        if (discordAccount.wallets && discordAccount.wallets.includes(address)) {
          setWalletAlreadyIncluded(true);
        }
      })
    }
  }, [discordAccount]);

  const connectDiscord = () => {
    try {
      // create a new XMLHttpRequest
      const xhr = new XMLHttpRequest()
      xhr.onload = async function () {
        let resp = JSON.parse(xhr.response)
        if (resp.avatar === "None") {
          resp.avatar = avatar
        }
        setDiscordAccount(resp);
        if (resp.username) {
          setIsDiscordConnected(true);
        }
        if (resp.discord_url) {
          window.location.href = resp.discord_url
        }
        if (resp.error) {
          openSnackbar(resp.error, 60000)
        }
      }

      xhr.onerror = function () {
        openSnackbar('Connection error!', 60000)
      }
      // open the request with the verb and the url
      xhr.open('GET', '/login/', false)
      // send the request
      xhr.send()
    } catch (error) {
      openSnackbar(error.message || 'Something went wrong.', 60000)
    }
  }

  const verifyAddress = async () => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error('Wallet not connected!')
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage) throw new Error('Wallet does not support message signing!')

      // Encode anything as bytes
      const message = new TextEncoder().encode('Hello, world!')
      // Sign the bytes using the wallet
      const signature = await signMessage(message)
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes())) throw new Error('Invalid signature!')

      // create a new XMLHttpRequest
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        let resp = JSON.parse(xhr.response)
        if (resp.status === 'ok') {
          setAddressVerified(true)
          openSnackbar(resp.message, 60000)
        } else {
          setAddressVerified(false)
          openSnackbar(resp.message || resp.status, 60000)
        }
      }

      xhr.onerror = function () {
        openSnackbar('Connection error!', 60000)
      }
      // open the request with the verb and the url
      xhr.open('POST', '/approve', false)
      // send the request
      xhr.send(JSON.stringify({address: publicKey.toBase58(), signature: signature}))
    } catch (error) {
      openSnackbar(error.message || 'Something went wrong.', 60000)
    }
  }
  const alternateVerifyAddress = () => {
    if (!walletAlreadyIncluded && !isAlternateAddressVerified) {
      if (discordAccount.wallets && discordAccount.wallets.includes(walletAddress)) {
        setWalletAlreadyIncluded(true);
      } else {
        try {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            let resp = JSON.parse(xhr.response)
            if (resp.status === 'ok') {
              setAlternateAddressVerified(resp.amount);
            } else {
              setAlternateAddressVerified(null);
              const message = resp.message ? resp.message : resp.status;
              openSnackbar(message, 60000);
            }
          };

          xhr.onerror = function () {
            openSnackbar('Connection error!', 60000)
          };
          // open the request with the verb and the url
          xhr.open('POST', '/alternate_approve', true)
          // send the request
          xhr.setRequestHeader('Content-Type', 'application/json');

          xhr.send(JSON.stringify({address: walletAddress}))
        } catch (error) {
          openSnackbar(error.message || 'Something went wrong.', 60000)
        }
      }
    }
  };

  return (
    <div className='login-block'>
      <div className='title'>
        <div className='logo'>
          <img alt='logo' src={logo}/>
        </div>
        <p className='description'>Member Verification</p>
      </div>
      <div className='content'>
        <img className='image' alt='robot' src={jeeves}/>
        <div className='buttons-group'>
          {!isDiscordConnected ? (
            <button
              id='discord_btn'
              className={`button ${isDiscordConnected ? 'btn-connected' : 'btn-not-connected'}`}
              onClick={connectDiscord}
              disabled={isDiscordConnected}
            > Connect Discord
            </button>
          ) : (
            <div className='w-100'>
              <button
                id='discord_btn'
                className={`button ${isDiscordConnected ? 'btn-connected' : 'btn-not-connected'}`}
                onClick={connectDiscord}
                disabled={isDiscordConnected}
              >
                <div className='connect-discord'>
                  <img alt='avatar' className='avatar' src={discordAccount.avatar}/>
                  <span style={{paddingLeft: 10}} className='text' title={discordAccount.username}>
                    {discordAccount.username}
                  </span>
                </div>
                <img alt='mark' className='checked' src={mark}/>
              </button>
              <p className='alternative-connect'
                 onClick={() => window.location.href = '/logout'}>Connect a different Discord ID</p>
            </div>
          )}
          <Tabs
            walletAlreadyIncluded={walletAlreadyIncluded}
            walletAddress={walletAddress}
            connected={connected}
            wallet={wallet}
            isAddressVerified={isAddressVerified}
            verifyAddress={verifyAddress}
            isAlternateAddressVerified={isAlternateAddressVerified}
            alternateVerifyAddress={alternateVerifyAddress}
            setWalletAddress={setWalletAddress}
          />
        </div>
      </div>
    </div>
  )
}

import React, {useState} from 'react';
import '../styles/Tabs.css'
import mark from "../svg/check-mark.svg";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {useSnackbar} from 'react-simple-snackbar';

export default function Tabs({
                               walletAlreadyIncluded,
                               walletAddress,
                               connected,
                               wallet,
                               isAddressVerified,
                               verifyAddress,
                               isAlternateAddressVerified,
                               alternateVerifyAddress,
                               setWalletAddress
}) {

  const [activeTab, setActiveTab] = useState(1);
  const [openSnackbar] = useSnackbar({position: 'top-right'});

  const formatAddress = (address) => {
    if (!address) {
      return ''
    }
    return `${address.slice(0, 4)}...${address.slice(-4)}`
  };

  const changeTab = (tab) => {
    if (wallet && isAddressVerified && tab === 2) {
      openSnackbar('Wallet already verified!', 60000)
    } else if(tab === 1 && (walletAlreadyIncluded || isAlternateAddressVerified) ) {
      openSnackbar('Wallet already verified!', 60000)
    } else {
      setActiveTab(tab);
    }
  };
  const disconnect = () => {
    const { solana } = window;
    if(solana) {
      solana.disconnect();
    }
  };

  return (
    <div className="tabs w-100">
      <ol className="tab-list">
        <li
          className={`tab-list-item ${activeTab === 1 ? ' tab-list-active' : ''} ${walletAlreadyIncluded || isAlternateAddressVerified ? 'disabled' : ''}`}
          onClick={() => changeTab(1)}
        >
          Solana
        </li>
        <li
          className={`tab-list-item ${activeTab === 2 ? ' tab-list-active' : ''}`}
          onClick={() => changeTab(2)}
        >
          Alternative verify
        </li>
      </ol>
      <div className="tab-content buttons-group">
        {activeTab === 1
          ? walletAlreadyIncluded
            ? <div className='verification-info info-text'>Your wallet address <span>{walletAddress}</span> already
              verified!</div>
            : <>
              {wallet && connected
                ? (
                  <div className='w-100 connect-button'>
                    <button id='connect' disabled={wallet} className={`button btn-connected`}>
                      <div className='text'>Connected ({formatAddress(walletAddress)})</div>
                      <img alt='mark' className='checked' src={mark}/>
                    </button>
                    <button id='disconnect' className={`button btn-connected`} onClick={disconnect}>
                      <div className='text'>Disconnect</div>
                      <img alt='mark' className='checked' src={mark}/>
                    </button>
                  </div>
                )
                : (
                  <div className={'solana-button'}>
                    <WalletMultiButton className={'button btn-not-connected'}>Connect Solana</WalletMultiButton>
                  </div>
                )
              }
              <button
                className={`button ${isAddressVerified ? 'btn-connected' : 'btn-not-connected'}`}
                onClick={verifyAddress}
                disabled={isAddressVerified}
              >
                {!isAddressVerified ? (
                  'Verify Solana Address'
                ) : (
                  <>
                    <span className='text'>Verified</span>
                    <img alt='mark' className='checked' src={mark}/>
                  </>
                )}
              </button>
            </>
          :
            <div className='alternate-content'>
              {walletAlreadyIncluded
                ? <div className='verification-info info-text'>Your wallet address <span>{walletAddress}</span> already
                  verified!</div>
                : <div className='description'>We can verify your wallet with a micro transaction to your own wallet.</div>
                }
              {isAlternateAddressVerified
                && <div className='verification-info'>
                  <div className='wallet'>Verification wallet address: <span>{formatAddress(walletAddress)}</span></div>
                  <div className='verification-text'>Now send <span>SOL {isAlternateAddressVerified}</span> to your own
                    wallet within 30 minutes. If you have a few transactions planned, please send this transaction first
                    before you proceed to other transacitons.
                  </div>
                </div>
              }
                <div className='margin-top'>
                  <div className='label'>Before we begin, please enter the wallet address you would like to verify:</div>
                  <div className={`input-group ${walletAlreadyIncluded || isAlternateAddressVerified ? 'disabled' : ''}`}>
                    <input
                      type="text"
                      autoComplete={'off'}
                      name="walletAddress"
                      value={walletAddress}
                      autoFocus={true}
                      onChange={(e) => setWalletAddress(e.target.value)}
                      disabled={walletAlreadyIncluded || isAlternateAddressVerified}
                    />
                    <div
                      className='verify-button'
                      onClick={alternateVerifyAddress}
                    >
                      Verify
                    </div>
                  </div>
                </div>
            </div>
        }
      </div>
    </div>
  )
}


import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import LoginPage from './pages/LoginPage'
import SnackbarProvider from 'react-simple-snackbar'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path='/'>
          <SnackbarProvider>
            <LoginPage />
          </SnackbarProvider>
        </Route>
      </Switch>
    </Router>
  )
}
